declare let window: any

import classNames from 'classnames'
import React from 'react'

import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import 'browser/components/atomic-elements/organisms/authentication-page/_authentication-page.scss'
import { NotificationBar } from '../notification-bar'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'

const LoadDocsLogo = require('images/navigation/loaddocs_logo_small.svg')
const VectorLogo = require('images/navigation/vector-white-on-black.svg')

interface IAuthenticationPageProps extends IBaseProps {
  bodyClassName?: string
}

export class AuthenticationPage extends React.Component<IAuthenticationPageProps, any> {

  public render() {
    const { bodyClassName, children, className } = this.props
    return (
      <div
        className={classNames(
          'flex flex-column items-center center mw6-m mw6-l h-100 c-authenticationPage-container',
          className
        )}
      >
        <div className="flex-grow-0 paper c-authenticationPage">
          <NotificationBar />
          {this.renderLogo()}
          <div className={classNames('ph4 pb4', bodyClassName)}>{children}</div>
        </div>
        {this.renderMobileWebFooter()}
      </div>
    )
  }

  /**
   * Renders a footer with links to more information, to help Campaign Registry
   * verify ownership/branding when updating our A2P Brand to use vctr.to (which
   * redirects to m.withvector.com by default, but we'd prefer they have a way
   * to get back to www.withvector.com to see more information about our
   * business.)
   */
  private renderMobileWebFooter() {
    return (
      <ComponentsContext.Consumer>
        {({ platform }) =>
          platform === PlatformType.MOBILE_WEB && (
            <div className="bottom-0 fixed gray f6 mb3">
              <a href="https://www.withvector.com" className="mr2">
                Vector
              </a>
              <span className="mh1">-</span>
              <a href="https://withvector.com/terms-of-service" className="mh2">
                Terms of Service
              </a>
              <span className="mh1">-</span>
              <a href="https://withvector.com/privacy" className="ml2">
                Privacy Policy
              </a>
            </div>
          )
        }
      </ComponentsContext.Consumer>
    )
  }

  private renderLogo() {
    const url = window.location.origin
    const logoSrc = url.indexOf('loaddocs') !== -1 ? LoadDocsLogo : VectorLogo
    return (
      <div className="pt4 ph4 tc">
        <img
          className="c-authenticationPage-logo mb3"
          src={logoSrc}
        />
      </div>
    )
  }
}
